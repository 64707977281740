import { ComponentProps, StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { MouseEventHandler, ReactNode, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { useEdge } from '../Edge';
import { StandaloneIconProps } from '../Icon';
import { isIconKey } from '../Icon/Icon.dictionary';
import { Dialog } from './Dialog';

interface StandaloneDialogProps extends StandaloneComponentProps<typeof Dialog> {
  closeButton?: ReactNode;
  content?: ReactNode;
  dialogIcon?: ReactNode | StandaloneIconProps['name'];
  headline?: ReactNode;
  onClose?: ComponentProps<'button'>['onClick'];
  onPrimaryButtonClick?: ComponentProps<'button'>['onClick'];
  onSecondaryButtonClick?: ComponentProps<'button'>['onClick'];
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
  hideCloseButton?: boolean;
}

export const StandaloneDialog: StandaloneComponent<StandaloneDialogProps> = ({
  closeButton = 'close',
  content,
  dialogIcon,
  headline,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButton,
  secondaryButton,
  hideCloseButton,
  ...props
}) => {
  const { ref: edgeRef } = useEdge();
  const ref = props.options?.ref as RefObject<HTMLDialogElement> | undefined;

  const handleClose: ComponentProps<'button'>['onClick'] = (e) => {
    onClose?.(e);
    ref?.current?.close();
  };

  const handleOutsideClick: MouseEventHandler<HTMLDialogElement> = (e) => {
    if (e.target === e.currentTarget) {
      ref?.current?.close();
    }
  };

  const handlePrimaryClick: ComponentProps<'button'>['onClick'] = (e) => {
    onPrimaryButtonClick?.(e);
    handleClose(e);
  };

  const handleSecondaryClick: ComponentProps<'button'>['onClick'] = (e) => {
    onSecondaryButtonClick?.(e);
    handleClose(e);
  };

  const showPrimaryButtonOrSecondaryButton = Boolean(primaryButton || secondaryButton);

  const closeButtonContent = isIconKey(closeButton) ? <Dialog.CloseIcon name={closeButton} /> : closeButton;
  const icon = isIconKey(dialogIcon) ? <Dialog.DialogIcon name={dialogIcon} /> : dialogIcon;

  if (!edgeRef?.current) {
    return null;
  }

  return createPortal(
    <Dialog {...props} onClick={handleOutsideClick}>
      {!hideCloseButton && (
        <Dialog.CloseButton
          options={mergeOptions({ onClick: handleClose }, props.options?.$closeButton)}
          content={closeButtonContent}
        />
      )}
      {icon}
      <Dialog.Headline>{headline}</Dialog.Headline>
      <Dialog.Content>{content}</Dialog.Content>
      {showPrimaryButtonOrSecondaryButton && (
        <Dialog.ButtonGroup>
          {primaryButton && (
            <Dialog.PrimaryActionButton
              options={mergeOptions({ onClick: handlePrimaryClick }, props.options?.$primaryActionButton)}
            >
              {primaryButton}
            </Dialog.PrimaryActionButton>
          )}
          {secondaryButton && (
            <Dialog.SecondaryActionButton
              options={mergeOptions({ onClick: handleSecondaryClick }, props.options?.$secondaryActionButton)}
            >
              {secondaryButton}
            </Dialog.SecondaryActionButton>
          )}
        </Dialog.ButtonGroup>
      )}
    </Dialog>,
    edgeRef.current,
  );
};
